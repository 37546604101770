import React, { useState, useEffect, useContext, useCallback } from 'react';
import './index.css';
import { useParams, useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
// import { confirmAlert } from 'react-confirm-alert'; // Import
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { format, intervalToDuration } from 'date-fns';

import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import { useStripe, useElements, CardNumberElement, CardExpiryElement ,CardCvcElement} from '@stripe/react-stripe-js';
import images from 'react-payment-inputs/images';

import { create_car_booking, get_car_by_id } from '../../API/index';
import { get_discount_price } from '../../API/car_booking';
import { validate_coupon_amount } from '../../API/coupon';
import { get_system_configurations } from '../../API/system_configurations';
import toast from 'react-hot-toast';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { AuthContext } from '../../contexts/AuthContext';
import { Socket_URL, subfix_domain } from '../../api';
import { get_coupon } from '../../API/coupon';
import TimingsCard from '../../Components/TimingsCard';
import ErrorModal from '../ErrorModal';
import ConfirmModal from '../ConfirmModal';
import { getCarName } from '../../helpers/carName';
import { format_date_time } from '../../helpers/formatDatTime';

import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from '@material-ui/core';

let __posting__ = false;
export default function PaymentDetails({ ...prop }) {
  const stripe = useStripe();
  const elements = useElements();
  const variables = prop.variables;
  const { id } = useParams();
  const navigate = useNavigate();
  const [carDetail, setCarDetail] = useState(null);

  const [carCurrentPrice, setCarCurrentPrice] = useState(
    carDetail?.daily_price
  );
  const [agreeTnC, setAgreeTnC] = useState(null);
  const [carCheckoutPrice, setCarCheckoutPrice] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [totalBookingAmount, setTotalBookingAmount] = useState(null);
  const [systemConfigurations, setSystemConfigurations] = useState(null);
  const [discountCode, setDiscountCode] = useState(null);
  const [coupon, setCoupon] = useState(null);

  const [errorMsg, setErrorMsg] = useState(false);
  const [toggleCvv, setToggleCvv] = useState(false);
  const [confModal, setConfModal] = useState(false);
  const [invalidDateModal, setInvalidDateModal] = useState(false);
  const [submitConfirmModal, setSubmitConfirmModal] = useState(false);
  const { wrapperProps, getCardImageProps, getCardNumberProps } =
    usePaymentInputs();

  const rate = systemConfigurations
    ? 1 + (Number(systemConfigurations?.tax) || 0)
    : 1;
  const numtoFixed = (num) => (Math.round(num * 100) / 100).toFixed(2);

  const handleCoupon = (e) => {
    e.preventDefault();
    get_coupon(discountCode.trim()).then((res) => {
      if (res.status === 200) {
        toast.success('Coupon Found');
        setCoupon(res.data);
      } else {
        toast.error('Not Found Coupon');
      }
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setErrorMsg(false);
    }, 6000);
  }, [errorMsg]);

  useEffect(() => {
    get_car_by_id(id, format_date_time(variables.fromDate, variables.fromTime), format_date_time(variables.untilDate, variables.untilTime)).then((res) => {
      if (res.status === 200 || res.status === 201 || res.status === 304) {
        setCarDetail(res.data);
      } else {
        console.log(res);
      }
    });
  }, []);

  const today = format(new Date(), 'yyyy-MM-dd');

  // const getInterval = (fromDate, untilDate) => {
  //   return intervalToDuration({
  //     start: fromDate,
  //     end: untilDate,
  //   }).days;
  // };
  // Setting Car Current Price
  useEffect(() => {
    const getCarPrice = () => {
      const basePrice = carDetail?.daily_price;
      // console.log(basePrice);
      // console.log(carDetail?.custom_prices.length);
      // return carDetail?.custom_prices.forEach((customPrice) => {
      return carDetail?.custom_prices.length > 0
        ? carDetail?.custom_prices.every((customPrice) => {
            if (
              today >= customPrice.start_date &&
              today <= customPrice.end_date
            ) {
              setCarCurrentPrice(Number(customPrice.price));
              return false;
            } else {
              setCarCurrentPrice(Number(basePrice));
              return true;
            }
          })
        : setCarCurrentPrice(Number(basePrice));
    };

    getCarPrice();
  }, [carDetail]);

  useEffect(() => {
    const carUpdatedPrice =
      carCurrentPrice && carCurrentPrice * variables.bookingPeriod;
    setCarCheckoutPrice(carUpdatedPrice);
  }, [
    carCurrentPrice,
    variables.bookingPeriod,
    variables.fromDate,
    variables.untilDate,
  ]);
  // console.log(carCurrentPrice);

  // const formatDiscountDate = (date = '') => {
  //   return (
  //     date !== '' &&
  //     date?.substring(0, 10) +
  //       'T' +
  //       (date?.substring(11, 16) === 'null' ? '00:00' : date?.substring(11, 16))
  //   );
  // };

  // console.log(variables.driverLicenceImg);

  useEffect(() => {
    get_system_configurations(authAxios).then((res) => {
      setSystemConfigurations(res.data[0]);
    });
  }, []);

  const format_date_time = (date, time) => {
    return date && format(date, 'yyyy-MM-dd') + 'T' + time;
  };

  useEffect(() => {
    const discount_data = {
      car_id: id,
      start_date: format_date_time(variables.fromDate, variables.fromTime),
      end_date: format_date_time(variables.untilDate, variables.untilTime),
      // start_date: format(variables.fromDate || new Date(),"yyyy-MM-dd'T'HH:mm"),
      // end_date: format(variables.untilDate || new Date(), "yyyy-MM-dd'T'HH:mm"),
    };
    systemConfigurations &&
      get_discount_price(authAxios, discount_data)
        .then((res) => {
          if (res.status == 200) {
            const coupon_amount =
              (coupon?.amount_type != 'A'
                ? (variables.bookingPeriod *
                    carDetail?.daily_price *
                    Math.abs(coupon?.amount)) /
                  100
                : Math.abs(coupon?.amount)) || 0;
            setDiscountPrice(res.data.discount + coupon_amount);
            setDiscountedPrice(
              (carCheckoutPrice ?? res.data.total_price) - (res.data.discount + coupon_amount)
            );
            setCarCheckoutPrice(res.data.total_price)
          } else {
            ErrorModal(res.response.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
  }, [systemConfigurations, variables.bookingPeriod, coupon, carCheckoutPrice]);

  const { user, authAxios } = useContext(AuthContext);
  const [selectedAdminUsers, setSelectedAdminUsers] = useState([]);

  const { readyState, sendJsonMessage } = useWebSocket(Socket_URL + `chats/`, {
    queryParams: {
      token: user ? user.token : 'anomynous',
      subfix_domain,
    },
    onOpen: () => {
      
    },
    onClose: () => {
      console.log('Disconnected!');
    },
    onMessage: (e) => {
      const data = JSON.parse(e.data);
      switch (data.type) {
        case 'chat_message_echo':
          break;
        default:
          // console.error('Unknown message type!');
          break;
      }
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const clearState = () => {
    variables.setCardNumber('');
    variables.setCardCvv('');
    variables.setExpiryDate('');
    variables.setExpiryYear('');
    variables.setCardHolderName('');
    variables.setPostalCode('');
    variables.setDriverLicenceImg(null);
    variables.setInsuranceImg(null);
    variables.setFirstName('');
    variables.setLastName('');
    variables.setEmail('');
    variables.setMobileNo('');
    variables.setFlightNo('');
    variables.setDriverLicenseNo('');
    variables.setAge('');
    variables.setAirlineName('');
    variables.setInsurancePolicyNo('');
    variables.setInsuranceExpDate('');
    variables.setInsuranceCompany('');
    variables.setAirportName(null);
    variables.setIsDifferentDropoff(false);
    variables.setDropoffAirportName(null);
    variables.setDeliveryCharge('');
  };

  const taxAmount =
    (systemConfigurations?.tax ?? 0) *
    ((discountedPrice ?? carCheckoutPrice) + Number(variables.deliveryCharge));

  const youngRenterFee =
    (systemConfigurations?.young_renter_fee / 100 ?? 0) *
    ((discountedPrice ?? carCheckoutPrice) + Number(variables.deliveryCharge));

  useEffect(() => {
    const bookingAmountTotal =
      (discountedPrice ?? carCheckoutPrice) +
      Number(variables.deliveryCharge) +
      taxAmount +
      (variables.age < 25 && youngRenterFee);
    setTotalBookingAmount(bookingAmountTotal < 0 ? 0 : bookingAmountTotal);
  }, [
    carCheckoutPrice,
    discountedPrice,
    taxAmount,
    youngRenterFee,
    variables.bookingPeriod,
    variables.deliveryCharge,
    variables.fromDate,
    variables.untilDate,
    variables.age,
  ]);

  const handleConfirm = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    // if (variables.age < 21) {
    //   ErrorModal(
    //     "Sorry, due to insurance and an increased chance of accidents we don't rent to anyone below the age of 21"
    //   );
    //   return;
    // }

    // if (variables.age < 25) {
    //   const total_price = Number(
    //     totalBookingAmount *
    //       (1 + (systemConfigurations?.young_renter_fee / 100 || 0))
    //   )?.toFixed(2);
    //   ConfirmModal(
    //     `We are really excited for you to be booking with us! However, due to the increased likelihood of accidents for young drivers,
    //   a fee of ${systemConfigurations?.young_renter_fee}% will be added to your final booking cost. Total Price: $${total_price}`,
    //     handleCouponConfirm
    //   );
    //   return;
    // }

    if (discountCode) {
      await handleCouponConfirm();
    } else await handleConfirmHandler(0);
  };

  const handleCouponConfirm = async () => {
    const res = await validate_coupon_amount(
      authAxios,
      totalBookingAmount,
      discountCode
    );

    if (res.data && res.data.message) {
      ConfirmModal(res.data.message, () =>
        handleConfirmHandler(res.data.coupon_amount)
      );
      return;
    } else await handleConfirmHandler(0);
  };

  const stripeErrorMesage = (msg) => {
    if (msg.includes('insufficient funds')) {
      return 'Your card has insufficient funds.'
    }
    if (msg.includes('Your card was declined')) {
      return 'Your card was declined; please contact your card issuer.'
    }
    if (msg.includes('while processing your card')) {
      return 'An error occurred while processing your card. Try again later.'
    }
    if (msg.includes('Your card has expired')) {
      return 'Your card has expired.'
    }
    if (msg.includes("Your card's security code is incorrect")) {
      return "Your card's security code is incorrect."
    }
    if (msg.includes('The entered number')) {
      return 'The entered number is not a valid credit card number, please check that the number entered matches the number on your card.'
    }
    if (msg.includes('Stripe is not connected.')) {
      return 'Stripe is not connected.'
    }
    if (msg.includes('No such product')) {
      return 'An error occurred while processing the subscription. Please use the other plan.'
    }
    if (msg.includes('stripe error')) {
      return ' Your card was declined; please contact your card issuer.'
    }
    return msg;
  }

  const handleConfirmHandler = async (coupon_amount) => {

    const start_date =
      format(variables.fromDate || new Date(), 'yyyy-MM-dd') +
      'T' +
      (variables.fromTime || '00:00') +
      ':00.000Z';
    const end_date =
      format(variables.untilDate || new Date(), 'yyyy-MM-dd') +
      'T' +
      (variables.untilTime || '23:59') +
      ':00.000Z';

    if (new Date(start_date).getTime() > new Date(end_date).getTime()) {
      setInvalidDateModal(true);
      return;
    }
    setConfModal(false);

    if (__posting__) return;
    __posting__ = true;
    
    const data = {
      customer_name: variables.firstName + ' ' + variables.lastName,
      card_holder: variables.cardHolderName,
      booked_by: variables.firstName + ' ' + variables.lastName,
      email: variables.email || 'test@email.com',
      phone: variables.mobileNo?.replace('(', '').replace(')', '').replace('+', '').replace('-', '') || '000000000',
      address: '_',
      postal_code: variables.postalCode,
      // license_number: variables.driverLicenseNo || 'U5146-37118-09374',
      license_number: variables.driverLicenseNo || '',
      // start_date: '2022-06-25T15:50:49.124Z',
      start_date: start_date,
      end_date: end_date,
      // end_date: '2022-06-29T15:50:49.124Z',
      pickup_location: variables.airportName ?? variables.dropoffAirportName,
      destination_location: variables.dropoffAirportName ?? variables.airportName,
      flight_number: variables.flightNo,
      insurance_policy: variables.insurancePolicyNo || '0000',
      insurance_company: variables.insuranceCompany || '_',
      insurance_expiry_date: format(
        variables.insuranceExpDate || new Date(),
        'yyyy-MM-dd'
      ),
      airline_name: variables.airlineName,
      price: numtoFixed(totalBookingAmount - Math.abs(coupon_amount)),
      // price: (discountedPrice + Number(variables.deliveryCharge)) * rate || 100,
      car: id,
      booking_user: 'customer',
      discount_price: numtoFixed(discountPrice),
      submitter: 'client',
      send_confirmation: true,
      age: variables.age,
      daily_price: carCurrentPrice
    };

    if (coupon && discountCode) {
      data.coupon_code = discountCode;
    }
    if (variables.driverLicenceImg != undefined) {
      data.license_image = variables.driverLicenceImg;
    }
    if (variables.InsuranceImg != undefined) {
      data.insurance_card = variables.InsuranceImg;
    }

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    });
    formData.append('payment_method_id', result.paymentMethod.id);

    prop.onLoading(true);
    create_car_booking(formData).then(async (res) => {
      prop.onLoading(false);
      if (res.status === 201) {
        setSubmitConfirmModal(true);
        __posting__ = false;

        clearState();
      } else {
        setErrorMsg('Confirmation Failed');
        if (res.response.data) {
          if (typeof res.response.data == 'object') {
            Object.keys(res.response.data).forEach((key) => {
              console.log(res.response.data)
              if (key !== 'non_field_errors') {
                // ErrorModal(key + ': ' + res.response.data[key]);
                ErrorModal(stripeErrorMesage(res.response.data[key]));
              }
              else ErrorModal(res.response.data[key]);
            });
          } else ErrorModal(res.response.data);
        }
        __posting__ = false;
      }
    });
  };

  // const getMilesLimit = () => {
  //   if (variables?.bookingPeriod <= 5) {
  //     return 150 * variables?.bookingPeriod;
  //   } else if (variables?.bookingPeriod <= 7) {
  //     return 750;
  //   } else if (variables?.bookingPeriod <= 12) {
  //     return 750 + 150 * (variables?.bookingPeriod - 5);
  //   } else if (variables?.bookingPeriod >= 13) {
  //     return 1500;
  //   }
  // };

  return (
    <div className='paymentDetails_wrapper'>
      <div className='paymentDetails_heading_wrapper'>
        <div className='paymentDetailMsgs'>
          {/* <div dangerouslySetInnerHTML={{__html: errorMsg && errorMsg}} /> */}
          {errorMsg && errorMsg}
        </div>
      </div>
      <div className='paymentDetails_container'>
        <div className='paymentDetails'>
          <div
            className='go_back'
            onClick={() => {
              navigate(-1);
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5'
              viewBox='0 0 20 20'
              fill='#46a8c8'
              width={30}
            >
              <path
                fillRule='evenodd'
                d='M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z'
                clipRule='evenodd'
              />
            </svg>
          </div>
          <div className='discount_row'>
            <div
              className='paymentDetails_heading'
              style={{ fontSize: '25px' }}
            >
              Payment Details
            </div>
          </div>
          <div className='productTimingsCard'>
            <TimingsCard
              props={variables}
              className={`productDetail_timingsCard_wrapper paymentPage`}
            />
          </div>
          <form
            action='#'
            onSubmit={(e) => {
              // handleConfirm(e);
              e.preventDefault();
              // EditModal();
              if (!agreeTnC) {
                toast.error('Please agree to Terms of service');
                return;
              }
              setConfModal(true);
            }}
            className='card_details'
            encType='multipart/form-data'
          >
            <div className='discount_row'>
              <div className='cardRow postalCode'>
                <div className='cardRow_text postalCode_text'>
                  <div className='card_label'>
                    Coupon Code <br />
                    <span>Enter the Coupon Code</span>
                  </div>
                </div>
                <div className='cardRow_input postalCode_input discount_input'>
                  <input
                    value={discountCode}
                    onChange={(e) => setDiscountCode(e.target.value)}
                    type='text'
                    placeholder='Coupon code'
                    name='cardHolderName'
                    id='cardHolderName'
                  />
                  <button className='apply_code_btn' onClick={handleCoupon}>
                    Apply Code
                  </button>
                </div>
              </div>
            </div>
            <div className='card_number' style={{marginBottom: '1rem'}}>
              <div className='card_number_header'>
                <div className='card_label' style={{marginBottom: '12px'}}>
                  Card Number <br />
                  <span>Enter the 16-digit card number on the card</span>
                </div>
              </div>
              <div className='card-num-element'>
                <div className='stripe-card-svg'>
                  <svg {...getCardImageProps({ images })} />
                </div>
                <CardNumberElement options={
                    {
                      style: {
                        base: {
                          color: '#fff',
                          fontSize: '16px',
                          padding: '24px',
                        },
                      },
                      classes: {
                        base: 'StripeElement StripeElement-rtcar-card-num'
                      }
                    }
                  }/>
              </div>
            </div>
            <div className='cardRow cvv_number'>
              <div className='cardRow_text  cvv_number_text'>
                <div className='card_label'>
                  CVC Number <br />
                  <span>Enter 3 or 4 digit number on the card</span>
                </div>
              </div>
              <div className='cardRow_input cvv_number_input'>
                <CardCvcElement options={
                  {
                    style: {
                      base: {
                        color: '#fff',
                        fontSize: '16px',
                        padding: '24px',
                      },
                    },
                  }
                } />
              </div>
            </div>
            <div className='cardRow cvv_number'>
              <div className='cardRow_text  cvv_number_text'>
                <div className='card_label'>
                Expiry Date <br />
                  <span>Enter the expiry date on the card</span>
                </div>
              </div>
              <div className='cardRow_input cvv_number_input'>
                <CardExpiryElement  options={
                    {
                      style: {
                        base: {
                          color: '#fff',
                          fontSize: '16px',
                          padding: '24px',
                          textAlign: 'center'
                        },
                      },
                    }
                  } />
              </div>
            </div>
            
            <div className='cardRow nameOnCard'>
              <div className='cardRow_text nameOnCard_text'>
                <div className='card_label'>
                  Name on Card <br />
                  <span>Enter the name on card</span>
                </div>
              </div>
              <div className='cardRow_input nameOnCard_input'>
                <input
                  value={variables.cardHolderName}
                  onChange={(e) => variables.setCardHolderName(e.target.value)}
                  required
                  type='text'
                  placeholder='Name'
                  name='cardHolderName'
                  id='cardHolderName'
                />
              </div>
            </div>
            <div className='cardRow postalCode'>
              <div className='cardRow_text postalCode_text'>
                <div className='card_label'>
                  Postal Code <br />
                  <span>Enter the postal code</span>
                </div>
              </div>
              <div className='cardRow_input postalCode_input'>
                <input
                  value={variables.postalCode}
                  onChange={(e) => variables.setPostalCode(e.target.value)}
                  required
                  type='number'
                  placeholder='Postal code'
                  name='cardHolderName'
                  id='cardHolderName'
                />
              </div>
            </div>
            <div className='cardRow postalCode'>
              <div className='postalCode_text'>
                <input
                  // required
                  value={agreeTnC}
                  type='checkbox'
                  name='T&C'
                  id='T&C'
                  hidden
                />
                <div className='card_label'>
                  <FormControlLabel
                    control={
                      <Checkbox
                        // checked={size?.find((e) => e === vSize)}
                        checked={Boolean(agreeTnC)}
                        onChange={(e) => setAgreeTnC(e.target.checked)}
                        sx={{
                          color: '#4fc8e9',
                          '&.Mui-checked': {
                            color: '#4fc8e9',
                          },
                        }}
                      />
                    }
                    label={''}
                  />
                  <span>I agree to the</span>{' '}
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.renotahoerentalcar.com/termsofservice'
                  >
                    terms of service
                  </a>
                </div>
              </div>
            </div>

            <div className='confirmation_btn'>
              <button
                disabled={
                  variables.cardHolderName !== '' &&
                  variables.postalCode !== '' &&
                  agreeTnC
                    ? false
                    : true
                }
                type='submit'
              >
                Book Now
              </button>
              <Modal
                open={confModal}
                onClose={() => setConfModal(false)}
                aria-labelledby='conf_modal'
                aria-describedby='modal-modal-description'
              >
                <div className='confModal'>
                    <>
                      <div className='paymentDetails_reviewCard'>
                        <div className='paymentDetails_reviewCard_Header'>
                          <img
                            src={carDetail?.car_images[0]?.image}
                            alt={carDetail?.make}
                          />
                        </div>
                        <div style={{ fontSize: '20px', fontWeight: 'bold' }}>
                          {getCarName(carDetail)}
                          <div
                            onClick={() => {
                              setConfModal(false);
                            }}
                            className='editModal_close'
                          >
                            <svg
                              width='25'
                              height='25'
                              viewBox='0 0 110 110'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                                stroke='#FF2552'
                                strokeWidth='11.625'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                              <path
                                d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                                stroke='#FF2552'
                                strokeWidth='11.625'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </div>
                        </div>

                        <div className='bookingTimings'>
                          <div className='bookingFromDate'>
                            <div>
                              {format(
                                new Date(
                                  format_date_time(
                                    variables.fromDate,
                                    variables.fromTime
                                  )
                                ),
                                'EEEE, MMM dd'
                              )}
                            </div>
                            <div>
                              {format(
                                new Date(
                                  format_date_time(
                                    variables.fromDate,
                                    variables.fromTime
                                  )
                                ),
                                "hh:mm aaaaa'm'"
                              )}
                            </div>
                          </div>
                          <div className='bookingFromDate'>
                            <div>
                              {format(
                                new Date(
                                  format_date_time(
                                    variables.untilDate,
                                    variables.untilTime
                                  )
                                ),
                                'EEEE, MMM dd'
                              )}
                            </div>
                            <div>
                              {format(
                                new Date(
                                  format_date_time(
                                    variables.untilDate,
                                    variables.untilTime
                                  )
                                ),
                                "hh:mm aaaaa'm'"
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='bookingDiliveryDetail'>
                          <div style={{ fontSize: '18px', fontWeight: 'bold' }}>
                            Delivery Location
                            <div className='bookingAirport'>
                              {variables.airportName}
                            </div>
                          </div>
                        </div>
                        <div className='bookingPaymentDetail'>
                          <div className='conf_detail'>
                            <div className='conf_detail_header'>
                              Payment Detail
                            </div>
                            <div className='payment_row'>
                              <span>
                                $
                                {numtoFixed(
                                  carCheckoutPrice / variables?.bookingPeriod
                                )}{' '}
                                x {variables?.bookingPeriod} days
                                {/* ${carDetail?.daily_price} * {variables?.bookingPeriod} days */}
                              </span>
                              <span>$ {numtoFixed(carCheckoutPrice)}</span>
                            </div>
                            <div className='payment_row'>
                              <span>Discount</span>
                              <span style={{ color: 'var(--light-blue)' }}>
                                - $ {numtoFixed(discountPrice)}
                              </span>
                            </div>
                            <div className='payment_row'>
                              <span>Delivery fee</span>
                              <span>
                                $ {variables.deliveryCharge ?? '00.00'}
                              </span>
                            </div>
                            {variables.age < 25 && (
                              <div className='payment_row'>
                                <span>Young driver fee</span>
                                <span>$ {youngRenterFee.toFixed(2)}</span>
                              </div>
                            )}
                            <div className='payment_row'>
                              <span>Tax ( {systemConfigurations?.tax} ) </span>
                              <span>$ {taxAmount.toFixed(2)}</span>
                            </div>
                            <div className='payment_row total_payment_row '>
                              <span>Total</span>
                              <span>
                                {/* $ {carCheckoutPrice + Number(variables.deliveryCharge)} */}
                                $ {totalBookingAmount?.toFixed(2)}
                                {/* {(discountedPrice ?? carCheckoutPrice) +
                              Number(variables.deliveryCharge)} */}
                              </span>
                              {/* <span>$ {carCheckoutPrice - discountPrice}</span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='confirm_modal_btn confirm_payment_btn'>
                        <button onClick={(e) => handleConfirm(e)}>
                          Confirm
                        </button>
                      </div>
                    </>
                </div>
              </Modal>
            </div>
          </form>
        </div>
        <div className='paymentDetails_card'>
          <div style={{ display: 'none' }} className='totalPrice'>
            {/* Total Price: ${discountedPrice + Number(variables.deliveryCharge)} */}
            Total Price: ${totalBookingAmount?.toFixed(2)}
          </div>
          <div className='paymentDetails_reviewCard'>
            <div className='paymentDetails_reviewCard_Header'>
              <img
                src={carDetail?.car_images[0]?.image}
                alt={carDetail?.make}
              />
            </div>
            <div style={{ fontSize: '20px', fontWeight: 'bold' }}>
              {getCarName(carDetail)}
            </div>

            <div className='bookingTimings'>
              <div className='bookingFromDate'>
                <div>
                  {format(
                    new Date(
                      format_date_time(variables.fromDate, variables.fromTime)
                    ),
                    'EEEE, MMM dd'
                  )}
                </div>
                <div>
                  {format(
                    new Date(
                      format_date_time(variables.fromDate, variables.fromTime)
                    ),
                    "hh:mm aaaaa'm'"
                  )}
                </div>
              </div>
              <div className='bookingFromDate'>
                <div>
                  {format(
                    new Date(
                      format_date_time(variables.untilDate, variables.untilTime)
                    ),
                    'EEEE, MMM dd'
                  )}
                </div>
                <div>
                  {format(
                    new Date(
                      format_date_time(variables.untilDate, variables.untilTime)
                    ),
                    "hh:mm aaaaa'm'"
                  )}
                </div>
              </div>
            </div>
            <div className='bookingDiliveryDetail'>
              <div style={{ fontSize: '18px', fontWeight: 'bold' }}>
                Delivery Location
                <div className='bookingAirport'>{variables.airportName}</div>
              </div>
            </div>
            <div className='bookingPaymentDetail'>
              <div className='conf_detail'>
                <div className='conf_detail_header'>Payment Detail</div>
                <div className='payment_row'>
                  <span>
                    ${numtoFixed(carCheckoutPrice / variables?.bookingPeriod)} x{' '}
                    {variables?.bookingPeriod} days
                    {/* ${carDetail?.daily_price} * {variables?.bookingPeriod} days */}
                  </span>
                  <span>$ {numtoFixed(carCheckoutPrice)}</span>
                </div>
                <div className='payment_row'>
                  <span>Discount</span>
                  <span style={{ color: 'var(--light-blue)' }}>
                    - $ {numtoFixed(discountPrice)}
                  </span>
                </div>
                <div className='payment_row'>
                  <span>Delivery fee</span>
                  <span>$ {variables.deliveryCharge ?? '00.00'}</span>
                </div>
                {variables.age < 25 && (
                  <div className='payment_row'>
                    <span>Young driver fee</span>
                    <span>$ {youngRenterFee.toFixed(2)}</span>
                  </div>
                )}
                <div className='payment_row'>
                  <span>Tax ( {systemConfigurations?.tax} ) </span>
                  <span>$ {taxAmount.toFixed(2)}</span>
                </div>
                <div className='payment_row total_payment_row '>
                  <span>Total</span>
                  <span>
                    {/* $ {carCheckoutPrice + Number(variables.deliveryCharge)} */}
                    $ {totalBookingAmount?.toFixed(2)}
                    {/* {(discountedPrice ?? carCheckoutPrice) +
                    Number(variables.deliveryCharge)} */}
                  </span>
                  {/* <span>$ {carCheckoutPrice - discountPrice}</span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={submitConfirmModal}
        onClose={() => setSubmitConfirmModal(false)}
        aria-labelledby='conf_modal'
        aria-describedby='modal-modal-description'
      >
        <div className=' confModal'>
          <div className='conf_hero_img'>
            <img src={carDetail?.car_images[0]?.image} alt='car_1' />
          </div>
          <div style={{ display: 'none' }} className='conf_detail'>
            <div className='conf_detail_header'>
              Payment Detail
              <div
                onClick={() => {
                  setSubmitConfirmModal(false);
                }}
                className='editModal_close'
              >
                <svg
                  width='25'
                  height='25'
                  viewBox='0 0 110 110'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M37.5625 37.5625L72.4375 72.4375M72.4375 37.5625L37.5625 72.4375L72.4375 37.5625Z'
                    stroke='#FF2552'
                    strokeWidth='11.625'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M55 103.438C81.7513 103.438 103.438 81.7513 103.438 55C103.438 28.2487 81.7513 6.5625 55 6.5625C28.2487 6.5625 6.5625 28.2487 6.5625 55C6.5625 81.7513 28.2487 103.438 55 103.438Z'
                    stroke='#FF2552'
                    strokeWidth='11.625'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
            </div>
            <div className='payment_row total_payment_row '>
              <span>Total Price:</span>
              <span>$ {discountedPrice}</span>
            </div>
            <div className='payment_row'>
              <span>{carDetail?.title}</span>
              <span>$ {carDetail?.total_price}</span>
            </div>
            <div className='payment_row'>
              <span>Discount</span>
              <span>$ {discountPrice}</span>
            </div>
          </div>
          <div className='conf_text'>
            Booking successful! Please check your email for a copy of your
            booking details.
          </div>
          <div className='confirm_modal_btn'>
            <button
              onClick={() => {
                setSubmitConfirmModal(false);
                navigate('/');
              }}
            >
              Back to home
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={invalidDateModal}
        onClose={() => setInvalidDateModal(false)}
        aria-labelledby='conf_modal'
        aria-describedby='modal-modal-description'
      >
        <div className='confModal'>
          <div className='conf_text' style={{ color: 'red' }}>
            Please select a valid return date.
          </div>
        </div>
      </Modal>
    </div>
  );
}
