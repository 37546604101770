import React, { useState } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { StylesProvider, createGenerateClassName } from '@mui/styles';
import { AuthContextProvider } from './contexts/AuthContext';
import { ThemeContextProvider } from './contexts/ThemeContext';
import HomePage from './pages/HomePage';
import FeaturedPage from './pages/FeaturedPage';
import ProductDetailPage from './pages/ProductDetailPage';
import PaymentPage from './pages/PaymentPage';
import InfoPage from './pages/InfoPage';
import Register from './pages/Register';
import Login from './pages/Login';
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';

import { add, format } from 'date-fns';
import InDevMode from './pages/InDevMode';
import ScrollToTop from "./Components/ScrollToTop";
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary";

const stripeKey =
  process.env.NODE_ENV === "development"
    ? "pk_test_51LXa59CslfodJUTgJzuTCNCbS6pmc12M1ozlD6wJLfx7lqfk6g1xCVwVRxgYdyPIX3SaKfQ4ozWJDOH0P1eRSwGg00Zw9lSAlJ"
    : "pk_live_51LXa59CslfodJUTgWc4eopGCF8oNmTMtS5SgmUfdlbeuA9kui2kFxiaSzIRkODJvbkJB24mgVsabciqbrSaiqd0800B8qTbjsO";

const stripePromise = loadStripe(stripeKey);

export default function App() {
  // Timing Card Variables
  const [airportName, setAirportName] = useState(null);
  const [isDifferentDropoff, setIsDifferentDropoff] = useState(false);
  const [dropoffAirportName, setDropoffAirportName] = useState(null);
  const [fromDate, setFromDate] = useState(add(new Date(), { days: 2 }));
  const [fromTime, setFromTime] = useState("10:00");
  const [untilDate, setUntilDate] = useState(add(new Date(), { days: 5 }));
  const [untilTime, setUntilTime] = useState("10:00");
  const [deliveryCharge, setDeliveryCharge] = useState("");
  // user Info Variables
  const [driverLicenceImg, setDriverLicenceImg] = useState();
  const [InsuranceImg, setInsuranceImg] = useState();
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobileNo, setMobileNo] = useState(null);
  const [flightNo, setFlightNo] = useState(null);
  const [driverLicenseNo, setDriverLicenseNo] = useState(null);
  const [airlineName, setAirlineName] = useState(null);
  const [insurancePolicyNo, setInsurancePolicyNo] = useState(null);
  const [insuranceExpDate, setInsuranceExpDate] = useState(null);
  const [insuranceCompany, setInsuranceCompany] = useState(null);
  const [age, setAge] = useState(null);
  // Payment Variables
  const [cardNumber, setCardNumber] = useState("");
  const [cardCvv, setCardCvv] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const generateClassName = createGenerateClassName({
    productionPrefix: "c",
  });

  const format_date = (date, time) => {
    return date && format(date, "yyyy-MM-dd") + "T" + time;
  };

  const from = new Date(format_date(fromDate, fromTime));
  const until = new Date(format_date(untilDate, untilTime));

  const getIntervalDays = (fromDate, fromTime, untilDate, untilTime) => {
    return Math.ceil(
      (new Date(format_date(untilDate, untilTime)).getTime() -
        new Date(format_date(fromDate, fromTime)).getTime()) /
        (1000 * 60 * 60 * 24)
    );
  };
  // function getDates(startDate, stopDate) {
  //   var dateArray = [];
  //   var currentDate = startDate;

  //   while (currentDate.getDate() <= stopDate.getDate()) {
  //     dateArray.push(new Date(currentDate));
  //     // currentDate = currentDate.addDays(1);
  //     currentDate = add(currentDate, { days: 1 });
  //   }
  //   return dateArray;
  // }
  const bookingPeriod = getIntervalDays(
    fromDate,
    fromTime,
    untilDate,
    untilTime
  );
  // const bookingPeriod = getDates(from, until).length;
  // console.log(getDates(from, until));

  // axios.defaults`.baseURL = 'https://app.rtcar-be.tk/api/';
  // axios.defaults.baseURL = 'http://rtcar-api-62b2a63cb76744bbf9948548.c.6261e579562e4f7f795e2a10.cycle.io/api/';

  // document.documentElement.style.setProperty('--new-variable', '#000');

  return (
    <StylesProvider generateClassName={generateClassName}>
      <BrowserRouter>
        <ScrollToTop />
        <ErrorBoundary>
          <Routes>
            {/* <Route path='/' element={<InDevMode />} /> */}
            <Route
              exact
              path="/"
              element={
                <AuthContextProvider>
                  <ThemeContextProvider>
                    <HomePage
                      airportName={airportName}
                      setAirportName={setAirportName}
                      isDifferentDropoff={isDifferentDropoff}
                      setIsDifferentDropoff={setIsDifferentDropoff}
                      dropoffAirportName={dropoffAirportName}
                      setDropoffAirportName={setDropoffAirportName}
                      fromDate={fromDate}
                      setFromDate={setFromDate}
                      fromTime={fromTime}
                      setFromTime={setFromTime}
                      untilDate={untilDate}
                      setUntilDate={setUntilDate}
                      untilTime={untilTime}
                      setUntilTime={setUntilTime}
                      deliveryCharge={deliveryCharge}
                      setDeliveryCharge={setDeliveryCharge}
                      bookingPeriod={bookingPeriod}
                    />
                  </ThemeContextProvider>
                </AuthContextProvider>
              }
            />
            <Route
              path="/register"
              element={
                <AuthContextProvider>
                  <Register />
                </AuthContextProvider>
              }
            />
            <Route
              path="/search"
              element={
                <AuthContextProvider>
                  <ThemeContextProvider>
                    <FeaturedPage
                      fromDate={fromDate}
                      setFromDate={setFromDate}
                      fromTime={fromTime}
                      setFromTime={setFromTime}
                      untilDate={untilDate}
                      untilTime={untilTime}
                      setUntilTime={setUntilTime}
                      setUntilDate={setUntilDate}
                      airportName={airportName}
                      setAirportName={setAirportName}
                      isDifferentDropoff={isDifferentDropoff}
                      setIsDifferentDropoff={setIsDifferentDropoff}
                      dropoffAirportName={dropoffAirportName}
                      setDropoffAirportName={setDropoffAirportName}
                      deliveryCharge={deliveryCharge}
                      setDeliveryCharge={setDeliveryCharge}
                      bookingPeriod={bookingPeriod}
                    />
                  </ThemeContextProvider>
                </AuthContextProvider>
              }
            />
            <Route
              path="/car/:id"
              element={
                <AuthContextProvider>
                  <ThemeContextProvider>
                    <ProductDetailPage
                      airportName={airportName}
                      setAirportName={setAirportName}
                      isDifferentDropoff={isDifferentDropoff}
                      setIsDifferentDropoff={setIsDifferentDropoff}
                      dropoffAirportName={dropoffAirportName}
                      setDropoffAirportName={setDropoffAirportName}
                      fromDate={fromDate}
                      setFromDate={setFromDate}
                      fromTime={fromTime}
                      setFromTime={setFromTime}
                      untilDate={untilDate}
                      setUntilDate={setUntilDate}
                      untilTime={untilTime}
                      setUntilTime={setUntilTime}
                      deliveryCharge={deliveryCharge}
                      setDeliveryCharge={setDeliveryCharge}
                      bookingPeriod={bookingPeriod}
                    />
                  </ThemeContextProvider>
                </AuthContextProvider>
              }
            />
            <Route
              path="/payment/:id"
              element={
                <AuthContextProvider>
                  <ThemeContextProvider>
                    <Elements stripe={stripePromise}>
                      <PaymentPage
                        airportName={airportName}
                        isDifferentDropoff={isDifferentDropoff}
                        dropoffAirportName={dropoffAirportName}
                        fromDate={fromDate}
                        fromTime={fromTime}
                        untilDate={untilDate}
                        untilTime={untilTime}
                        driverLicenceImg={driverLicenceImg}
                        InsuranceImg={InsuranceImg}
                        firstName={firstName}
                        lastName={lastName}
                        email={email}
                        mobileNo={mobileNo}
                        flightNo={flightNo}
                        driverLicenseNo={driverLicenseNo}
                        airlineName={airlineName}
                        insurancePolicyNo={insurancePolicyNo}
                        insuranceExpDate={insuranceExpDate}
                        insuranceCompany={insuranceCompany}
                        cardNumber={cardNumber}
                        cardCvv={cardCvv}
                        expiryDate={expiryDate}
                        expiryYear={expiryYear}
                        cardHolderName={cardHolderName}
                        postalCode={postalCode}
                        age={age}
                        setCardNumber={setCardNumber}
                        setCardCvv={setCardCvv}
                        setExpiryDate={setExpiryDate}
                        setExpiryYear={setExpiryYear}
                        setCardHolderName={setCardHolderName}
                        setPostalCode={setPostalCode}
                        setDriverLicenceImg={setDriverLicenceImg}
                        setInsuranceImg={setInsuranceImg}
                        setFirstName={setFirstName}
                        setLastName={setLastName}
                        setEmail={setEmail}
                        setMobileNo={setMobileNo}
                        setFlightNo={setFlightNo}
                        setDriverLicenseNo={setDriverLicenseNo}
                        setAirlineName={setAirlineName}
                        setInsurancePolicyNo={setInsurancePolicyNo}
                        setInsuranceExpDate={setInsuranceExpDate}
                        setInsuranceCompany={setInsuranceCompany}
                        setAirportName={setAirportName}
                        setIsDifferentDropoff={setIsDifferentDropoff}
                        setDropoffAirportName={setDropoffAirportName}
                        setFromDate={setFromDate}
                        setFromTime={setFromTime}
                        setUntilDate={setUntilDate}
                        setUntilTime={setUntilTime}
                        setDeliveryCharge={setDeliveryCharge}
                        deliveryCharge={deliveryCharge}
                        bookingPeriod={bookingPeriod}
                        setAge={setAge}
                      />
                    </Elements>
                  </ThemeContextProvider>
                </AuthContextProvider>
              }
            />
            <Route
              path="/info/:id"
              element={
                <AuthContextProvider>
                  <ThemeContextProvider>
                    <InfoPage
                      driverLicenceImg={driverLicenceImg}
                      InsuranceImg={InsuranceImg}
                      firstName={firstName}
                      lastName={lastName}
                      email={email}
                      mobileNo={mobileNo}
                      flightNo={flightNo}
                      driverLicenseNo={driverLicenseNo}
                      airlineName={airlineName}
                      insurancePolicyNo={insurancePolicyNo}
                      insuranceExpDate={insuranceExpDate}
                      insuranceCompany={insuranceCompany}
                      setDriverLicenceImg={setDriverLicenceImg}
                      setInsuranceImg={setInsuranceImg}
                      setFirstName={setFirstName}
                      setLastName={setLastName}
                      setEmail={setEmail}
                      setMobileNo={setMobileNo}
                      setFlightNo={setFlightNo}
                      age={age}
                      setDriverLicenseNo={setDriverLicenseNo}
                      setAirlineName={setAirlineName}
                      setInsurancePolicyNo={setInsurancePolicyNo}
                      setInsuranceExpDate={setInsuranceExpDate}
                      setInsuranceCompany={setInsuranceCompany}
                      fromDate={fromDate}
                      untilDate={untilDate}
                      airportName={airportName}
                      isDifferentDropoff={isDifferentDropoff}
                      dropoffAirportName={dropoffAirportName}
                      fromTime={fromTime}
                      untilTime={untilTime}
                      bookingPeriod={bookingPeriod}
                      setFromDate={setFromDate}
                      setFromTime={setFromTime}
                      setUntilTime={setUntilTime}
                      setUntilDate={setUntilDate}
                      setAirportName={setAirportName}
                      setIsDifferentDropoff={setIsDifferentDropoff}
                      setDropoffAirportName={setDropoffAirportName}
                      deliveryCharge={deliveryCharge}
                      setDeliveryCharge={setDeliveryCharge}
                      setAge={setAge}
                    />
                  </ThemeContextProvider>
                </AuthContextProvider>
              }
            />
            <Route
              path="/contact_us"
              element={
                <AuthContextProvider>
                  <ThemeContextProvider>
                    <ContactUs />
                  </ThemeContextProvider>
                </AuthContextProvider>
              }
            />
            <Route
              path="/about_us"
              element={
                <AuthContextProvider>
                  <ThemeContextProvider>
                    <AboutUs />
                  </ThemeContextProvider>
                </AuthContextProvider>
              }
            />
            <Route
              path="login/:buffString"
              element={
                <AuthContextProvider>
                  <Login />
                </AuthContextProvider>
              }
            />
          </Routes>
        </ErrorBoundary>
      </BrowserRouter>
    </StylesProvider>
  );
}
