import React, { Component } from "react";
import Navbar from "../Navbar";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, showError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true, error: error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error("ErrorBoundary caught an error", error, errorInfo);
    this.setState({ error: error });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ height: "80vh" }}>
          <Navbar />
          <div
            style={{
              textAlign: "center",
              margin: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "80vh",
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            <h1>Something went wrong.</h1>
            <p>Please try again or contact support if the issue persists.</p>
            <div
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              <button
                onClick={() => window.location.reload()}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  border: "none", // No border
                  borderRadius: "5px", // Rounded corners
                  cursor: "pointer", // Pointer cursor
                }}
              >
                Retry
              </button>
              <button
                onClick={() => {
                  this.setState({ showError: !this.state.showError });
                }}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  backgroundColor: "#f44336", // Red background
                  color: "white", // White text
                  border: "none", // No border
                  borderRadius: "5px", // Rounded corners
                  cursor: "pointer", // Pointer cursor
                }}
              >
                View Error
              </button>
            </div>
            {this.state.showError && (
              <div style={{ textAlign: "left", margin: "20px" }}>
                <h2>Error Details</h2>
                <pre>{this.state.error && this.state.error.toString()}</pre>
              </div>
            )}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
