import React, { useContext, useEffect, useRef, useState } from "react";
import "./index.css";
import "./unavailability.css";
import CarThumbnailCarousel from "../../CarThumbnailCarousel";
import { Link, useNavigate } from "react-router-dom";
import FeaturesCard from "./FeaturesCard";
import { format, intervalToDuration, addDays } from "date-fns";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import CalenderHeader from "../../../Components/CalenderHeader";
import { get_car_validate_reference } from "../../../API/index";
import { getCarUnavailablity } from "../../../helpers/unavailablity";
import ErrorModal from "../../ErrorModal";
import TimingsCard from "../../../Components/TimingsCard";
import { getCarName } from "../../../helpers/carName";
import { get_discount_price } from "../../../API/car_booking";
import { AuthContext } from "../../../contexts/AuthContext";
import { format_date_time } from "../../../helpers/formatDatTime";

const getDayOfYear = (date) => {
  const onejan = new Date(date.getFullYear(), 0, 1);
  return Math.ceil((date - onejan) / 86400000);
};

export default function ProductDetail({ data, props }) {
  // fromDate, untilDate
  const calendarRef = useRef();
  const carousel = useRef();
  // const [fromDate, setFromDate] = useState(null);
  const [description_nav, setDescription_nav] = useState("description");
  const [isReadMore, setIsReadMore] = useState(true);
  const [carCurrentPrice, setCarCurrentPrice] = useState(data?.daily_price);
  const [carCheckoutPrice, setCarCheckoutPrice] = useState(null);
  const [unavailable, setUnavailable] = useState(false);
  const navigate = useNavigate();
  const [calenderType, setCalenderType] = useState("dayGridMonth");
  const [selectedCadData, setSelectedCadData] = useState([]);
  const carUnavailablityData = data.unavailabilities;
  const carDetail = data;
  const [carBaseDiscount, setCarBaseDiscount] = useState(0);
  const { authAxios } = useContext(AuthContext);

  console.log("🚀OUTPUT --> selectedCadData:", selectedCadData);
  // console.log(carDetail);

  const today = format(new Date(), "yyyy-MM-dd");
  const getInterval = (fromDate, untilDate) => {
    return intervalToDuration({
      start: fromDate,
      end: untilDate,
    }).days;
  };

  const IncrementDay = (initialDate) => {
    return addDays(initialDate, 1);
  };
  // Setting Car Current Price
  useEffect(() => {
    // const getCarPrice = () => {
    //   const basePrice = carDetail?.daily_price;
    //   // return carDetail?.custom_prices.forEach((booking) => {
    //   return carDetail?.custom_prices?.every((customPrice) => {
    //     if (today >= customPrice.start_date && today <= customPrice.end_date) {
    //       setCarCurrentPrice(customPrice.price);
    //       return false;
    //     } else {
    //       setCarCurrentPrice(basePrice);
    //       return true;
    //     }
    //   });
    // };

    const discount_data = {
      car_id: carDetail?.id,
      start_date: format_date_time(props.fromDate, props.fromTime),
      end_date: format_date_time(props.untilDate, props.untilTime),
      // start_date: format(props.fromDate || new Date(),"yyyy-MM-dd'T'HH:mm"),
      // end_date: format(props.untilDate || new Date(), "yyyy-MM-dd'T'HH:mm"),
    };
    get_discount_price(authAxios, discount_data)
      .then((res) => {
        if (res.status === 200) {
          setCarBaseDiscount(res.data.discount || 0);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setCarCurrentPrice(carDetail?.daily_price);
    // getCarPrice();

    const fromDate =
      format(props.fromDate || new Date(), "yyyy-MM-dd") +
      "T" +
      (props.fromTime || "00:00") +
      ":00.000Z";

    const untilDate =
      format(props.untilDate || new Date(), "yyyy-MM-dd") +
      "T" +
      (props.untilTime || "00:00") +
      ":00.000Z";

    if (carDetail?.id) {
      get_car_validate_reference(carDetail?.id, fromDate, untilDate).then(
        (res) => {
          console.log("props, cardetails", carDetail, props, res);
          if (res.status !== 200) {
            setUnavailable(true);
          } else {
            setUnavailable(false);
          }
        }
      );
    }
  }, [
    props.fromDate,
    props.untilDate,
    props.fromTime,
    props.untilTime,
    carDetail?.id,
  ]);

  useEffect(() => {
    let extraDays = 0;
    if (
      Number(props.untilTime.slice(0, 2)) < Number(props.fromTime.slice(0, 2))
    ) {
      extraDays = 1;
    }

    // const carUpdatedPrice =  carCurrentPrice && carCurrentPrice * (interval === 0 ? 1 : interval);
    const carUpdatedPrice = Number(
      carCurrentPrice &&
        carCurrentPrice *
          (props.bookingPeriod === 0 ? 1 : props.bookingPeriod) +
          (props.deliveryCharge && props.bookingPeriod < 7
            ? Number(props.deliveryCharge)
            : 0)
    );
    setCarCheckoutPrice(carUpdatedPrice.toFixed(2));
  }, [
    carCurrentPrice,
    props.fromDate,
    props.untilDate,
    props.fromTime,
    props.untilTime,
    props.bookingPeriod,
    props.deliveryCharge,
  ]);

  // Setting Unavailibility data in the Calender
  useEffect(() => {
    // bookingData?.forEach((booking) => {
    let carCalenderData = [];
    let selectedDates = [];
    let SelectedDate = new Date(props.fromDate);

    // carUnavailablityData?.forEach((booking) => {
    //   const interval = getInterval(
    //     new Date(booking.start_date),
    //     new Date(booking.end_date)
    //   );
    //   let tempDate = new Date(booking.start_date);
    //   let tempData = [];
    //   for (var i = 0; i <= interval; i++) {
    //     if (i === 0) {
    //       tempData.push({
    //         date: tempDate.toISOString().split('T')[0],
    //         data: {
    //           title: `NA`,
    //           id: booking.id,
    //           date: format(new Date(booking.start_date), 'yyyy-MM-dd'),
    //           allDay: false,
    //           classNames: '',
    //           reason: booking.reason ?? '',
    //           start_date: booking.start_date,
    //           end_date: booking.end_date,
    //           selected: 'NA',
    //         },
    //       });
    //     } else {
    //       tempDate = IncrementDay(tempDate);
    //       tempData.push({
    //         date: tempDate.toISOString().split('T')[0],
    //         data: {
    //           title: `NA`,
    //           id: booking.id,
    //           date: format(tempDate, 'yyyy-MM-dd'),
    //           allDay: false,
    //           classNames: '',
    //           reason: booking.reason ?? '',
    //           start_date: booking.start_date,
    //           end_date: booking.end_date,
    //           selected: 'NA',
    //         },
    //       });
    //     }
    //   }
    //   carCalenderData = carCalenderData.concat(tempData);
    // });

    const trip_buffer_time = Number(
      carDetail?.trip_preference?.trip_buffer_time || 0
    );
    carDetail?.car_bookings
      ?.filter((item) => item.status != "Cancelled")
      .forEach((booking) => {
        let interval = getInterval(
          new Date(booking.start_date),
          new Date(booking.end_date)
        );
        if (
          Number(booking.end_date.slice(11, 13)) <
          Number(booking.start_date.slice(11, 13))
        ) {
          interval = interval + 1;
        }

        let tempDate = new Date(booking.start_date);
        let tempData = [];

        let isEndDayAvailable = 0;
        if (Number(booking.end_date.slice(11, 13)) + trip_buffer_time < 24) {
          isEndDayAvailable = 1;
        }

        for (var i = 0; i <= interval - isEndDayAvailable; i++) {
          if (i === 0) {
            tempData.push({
              date: tempDate.toISOString().split("T")[0],
              data: {
                title: `Booked`,
                id: booking.id,
                date: format(new Date(booking.start_date), "yyyy-MM-dd"),
                allDay: false,
                classNames: "",
                reason: booking.reason ?? "",
                start_date: booking.start_date,
                end_date: booking.end_date,
                selectable: false,
                selected: "Booked",
              },
            });
          } else {
            tempDate = IncrementDay(tempDate);

            tempData.push({
              date: tempDate.toISOString().split("T")[0],
              data: {
                title: `Booked`,
                id: booking.id,
                date: format(tempDate, "yyyy-MM-dd"),
                allDay: false,
                classNames: "",
                reason: booking.reason ?? "",
                start_date: booking.start_date,
                end_date: booking.end_date,
                selectable: false,
                selected: "Booked",
              },
            });
          }
        }

        carCalenderData = carCalenderData.concat(tempData);
      });

    // remove duplicate dates
    const keys = ["date"];
    carCalenderData = carCalenderData
      .filter(
        (
          (s) => (o) =>
            ((k) => !s.has(k) && s.add(k))(keys.map((k) => o[k]).join("|"))
        )(new Set())
      )
      .map((item) => item.data);

    let extraDays = 1;
    if (props.untilTime == "00:00" && props.fromTime == "00:00") {
      extraDays = 0;
    }

    const untilDateNumber =
      Number(props.untilTime.slice(0, 2)) * 60 +
      Number(props.untilTime.slice(3, 5));
    const fromDateNumber =
      Number(props.fromTime.slice(0, 2)) * 60 +
      Number(props.fromTime.slice(3, 5));
    if (untilDateNumber > fromDateNumber) {
      extraDays = extraDays - 1;
    }

    for (var i = 0; i <= props.bookingPeriod + extraDays - 1; i++) {
      SelectedDate = i == 0 ? SelectedDate : IncrementDay(SelectedDate);
      const date = format(new Date(SelectedDate), "yyyy-MM-dd");

      // check if select day is matching to booking/unavailibility day
      const selectable =
        carCalenderData.map((item) => item.date).indexOf(date) >= 0;

      if (!selectable) {
        selectedDates.push({
          title: `Selected`,
          id: Math.floor(1000 + Math.random() * 9000),
          date: date,
          allDay: false,
          classNames: "",
          reason: "",
          start_date: props.fromDate,
          end_date: props.untilDate,
          selected: "mid",
          selectable: selectable,
        });
      }

      // if (i === 0) {
      //   selectedDates.push({
      //     title: `Selected`,
      //     id: Math.floor(1000 + Math.random() * 9000),
      //     date: date,
      //     allDay: false,
      //     classNames: `bookingSelectedFirst`,
      //     reason: '',
      //     start_date: props.fromDate,
      //     end_date: props.untilDate,
      //     selectable: selectable,
      //     selected: 'first',
      //   });
      // } else {
      //   if (i === props.bookingPeriod - 1) {
      //     selectedDates.push({
      //       title: `Selected`,
      //       id: Math.floor(1000 + Math.random() * 9000),
      //       date: date,
      //       allDay: false,
      //       classNames: `bookingSelectedLast`,
      //       reason: '',
      //       selectable: selectable,
      //       start_date: props.fromDate,
      //       end_date: props.untilDate,
      //       selected: 'last',
      //     });
      //   } else {
      //     selectedDates.push({
      //       title: `Selected`,
      //       id: Math.floor(1000 + Math.random() * 9000),
      //       date: date,
      //       allDay: false,
      //       classNames: 'bookingSelectedMid',
      //       reason: '',
      //       start_date: props.fromDate,
      //       end_date: props.untilDate,
      //       selected: 'mid',
      //       selectable: selectable,
      //     });
      //   }
      // }
    }
    carCalenderData.push(...selectedDates);
    console.log(carUnavailablityData, carCalenderData);
    setSelectedCadData(carCalenderData);
  }, [
    carUnavailablityData,
    props.fromDate,
    props.untilDate,
    props.fromTime,
    props.untilTime,
    props.bookingPeriod,
  ]);

  useEffect(() => {
    const calApi = calendarRef.current?.getApi();
    calApi.gotoDate(new Date(props.fromDate));
  }, [props.fromDate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!props.dropoffAirportName || props.dropoffAirportName === "") {
      navigate("/");
    }
  }, []);

  function renderEventContent(eventInfo) {
    const selectable = eventInfo.event.extendedProps.selectable;
    return (
      <div
        // title={eventInfo.event.extendedProps.reason}
        className={`price_per_day_event_block unavailability_event_block 
          ${
            eventInfo.event.extendedProps.selected === "first" &&
            props.bookingPeriod === 1 &&
            `bookingSelectedLast ${selectable ? "bookingSelected" : ""}`
          }
          ${eventInfo.event.extendedProps.selected === "Booked" ? "Booked" : ""}
          `}
        // ${eventInfo.event.extendedProps.selected === 'NA' ? 'Booked' : ''}
      >
        {/* <div className='event_block_type'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z'
            />
          </svg>
        </div> */}
      </div>
    );
  }

  const renderDay = (info, create) => {
    const offset = info.date.getTimezoneOffset();
    const myDate = new Date(info.date.getTime() - offset * 60 * 1000);
    const isBooked =
      selectedCadData.findIndex(
        (item) =>
          item.date === myDate.toISOString().split("T")[0] &&
          item.title === "Booked"
      ) >= 0;
    const isUnavailbility =
      selectedCadData.findIndex(
        (item) =>
          item.date === myDate.toISOString().split("T")[0] &&
          item.title === "NA"
      ) >= 0;
    const isSelected =
      selectedCadData.findIndex(
        (item) =>
          item.date === myDate.toISOString().split("T")[0] &&
          item.title === "Selected"
      ) >= 0;

    let color = "";
    if (isSelected) {
      color = "#f0ce4ad4";
    }
    if (myDate.toISOString().split("T")[0] === today) {
      color = "white";
    }
    if (isBooked || isUnavailbility) {
      color = "#ff2552";
    }
    const element = create(
      "span",
      {
        id: "fc-day-span-" + getDayOfYear(info.date),
        style: {
          color: color,
          textDecoration: isBooked || isUnavailbility ? "line-through" : "",
          fontWeight:
            today === myDate.toISOString().split("T")[0] ? "bold" : "",
          fontSize: today === myDate.toISOString().split("T")[0] ? "18px" : "",
        },
      },
      info.dayNumberText
    );
    return element;
  };

  const handleMoveInfoPage = (id) => {
    const fromDate =
      format(props.fromDate || new Date(), "yyyy-MM-dd") +
      "T" +
      (props.fromTime || "00:00") +
      ":00.000Z";

    const untilDate =
      format(props.untilDate || new Date(), "yyyy-MM-dd") +
      "T" +
      (props.untilTime || "00:00") +
      ":00.000Z";

    // get_car_validate_reference(id, fromDate, untilDate).then((res) => {
    //   if (res.status == 200) {
    //     navigate(`/info/${id}`);
    //   } else {
    //     ErrorModal(res.response.data.message);
    //   }
    // });
    if (!unavailable) navigate(`/info/${id}`);
  };

  const caraoselWidth = carousel?.current?.offsetWidth;
  const caraoselHeight = caraoselWidth / 1.77777;

  const getMilesLimit = () => {
    const booked_months = Math.floor(props?.bookingPeriod / 31);
    const booked_extra_days = props?.bookingPeriod % 31;
    const total_miles_for_months = booked_months * 1500;
    var total_miles_for_extra_days = 0;
    if (booked_extra_days <= 5) {
      total_miles_for_extra_days = 150 * booked_extra_days;
    } else if (booked_extra_days <= 7) {
      total_miles_for_extra_days = 750;
    } else if (booked_extra_days <= 12) {
      total_miles_for_extra_days = 750 + 150 * (booked_extra_days - 7);
    } else if (booked_extra_days >= 13) {
      total_miles_for_extra_days = 1500;
    }
    return total_miles_for_months + total_miles_for_extra_days;
  };

  return (
    <div className="productDetail" style={{ marginTop: "0px" }}>
      <div
        className="go_back"
        onClick={(e) => {
          e.preventDefault();
          navigate("/search");
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="#46a8c8"
          width={30}
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div className="productDetail_hero_section">
        <div
          className="img_section"
          ref={carousel}
          // style={{ height: caraoselHeight ? caraoselHeight : 'auto' }}
        >
          <CarThumbnailCarousel
            className="car_detail_carousel"
            title={data?.title}
            images={data?.car_images}
            unavailable={unavailable}
          />
        </div>
        <div className="img_detail">
          <div
            className="product_tags pointer"
            onClick={(e) => {
              e.preventDefault();
              // navigate('/search');
            }}
          >
            {data?.is_featured && (
              <span className="product_tag">Featured Car</span>
            )}
            {/* {data?.tags.map((tag) => (
              <span className='product_tag'>{tag}</span>
            ))} */}
          </div>
          <div
            className="product_title "
            onClick={(e) => {
              e.preventDefault();
              // navigate('/search');
            }}
          >
            {getCarName(data)}
          </div>
          <div
            className="product_similar"
            onClick={(e) => {
              e.preventDefault();
              // navigate('/search');
            }}
          >
            {data?.similarTo}
          </div>
          <div style={{ display: "none" }} className="product_rating">
            <span className="rating_starts">
              <svg
                width="22"
                height="20"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.954 0L14.0787 6.33026L21.0663 7.35159L16.0101 12.2762L17.2034 19.2335L10.954 15.947L4.70467 19.2335L5.89791 12.2762L0.841797 7.35159L7.82935 6.33026L10.954 0Z"
                  fill="#FFAF13"
                />
              </svg>
              <svg
                width="22"
                height="20"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.954 0L14.0787 6.33026L21.0663 7.35159L16.0101 12.2762L17.2034 19.2335L10.954 15.947L4.70467 19.2335L5.89791 12.2762L0.841797 7.35159L7.82935 6.33026L10.954 0Z"
                  fill="#FFAF13"
                />
              </svg>
              <svg
                width="22"
                height="20"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.954 0L14.0787 6.33026L21.0663 7.35159L16.0101 12.2762L17.2034 19.2335L10.954 15.947L4.70467 19.2335L5.89791 12.2762L0.841797 7.35159L7.82935 6.33026L10.954 0Z"
                  fill="#FFAF13"
                />
              </svg>
              <svg
                width="22"
                height="20"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.954 0L14.0787 6.33026L21.0663 7.35159L16.0101 12.2762L17.2034 19.2335L10.954 15.947L4.70467 19.2335L5.89791 12.2762L0.841797 7.35159L7.82935 6.33026L10.954 0Z"
                  fill="#FFAF13"
                />
              </svg>
              <svg
                width="22"
                height="20"
                viewBox="0 0 22 20"
                fill="#373b4f"
                // stroke='#FFAF13'
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.954 0L14.0787 6.33026L21.0663 7.35159L16.0101 12.2762L17.2034 19.2335L10.954 15.947L4.70467 19.2335L5.89791 12.2762L0.841797 7.35159L7.82935 6.33026L10.954 0Z" />
              </svg>
            </span>
            {/* ( {data?.review} ) Reviews */}
          </div>
          {/* <div
            className='product_short_descp pointer'
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {data?.features?.length !== 0 && (
              <FeaturesCard features={data?.features} />
            )}
          </div> */}
          <div
            className="date_row width100"
            onClick={(e) => {
              e.preventDefault();
              // navigate('/search');
            }}
          >
            <div className="car_tags">
              <span>
                <img src="/assets/imgs/automatic.png" alt="" />
                {data?.type}
              </span>
              <span>
                <img src="/assets/imgs/car.png" alt="" />
                {data?.number_of_seats}
              </span>
              <span>
                <img src="/assets/imgs/people.png" alt="" />
                {data?.number_of_bags} Seats
              </span>
            </div>
          </div>
          <div className="product_buttons">
            {/* <Link className='product_buy_now' to={`/payment/${data?.id}`}> */}
            {unavailable ? (
              // <button disabled className='product_buy_now unavailable'>
              <button
                className="product_buy_now unavailable"
                onClick={(e) => {
                  e.preventDefault();
                  handleMoveInfoPage(data?.id);
                }}
              >
                Unavailable
              </button>
            ) : (
              <button
                className="product_buy_now"
                // onClick={() => navigate(selectPath)}
                onClick={(e) => {
                  e.preventDefault();
                  handleMoveInfoPage(data?.id);
                }}
                type="submit"
              >
                Select
              </button>
            )}
            <div className="product_totalCost">
              Total Cost: ${(carCheckoutPrice - carBaseDiscount).toFixed(2)}
            </div>
            <div className="product_dailyCost">
              Daily Rate: ${carCurrentPrice ?? data?.daily_price}
            </div>
          </div>
          <div style={{ marginTop: "8px", fontSize: "14px" }}>
            Mile Limit: {getMilesLimit()}
            {/* Mile Limit: {carDetail?.daily_max_miles * props?.bookingPeriod} */}
          </div>

          <div className="prodictTimingsLocation">
            <div
              className="prodictTimingsCard"
              style={{ marginTop: "35px", flex: "4 0" }}
            >
              <TimingsCard
                props={props}
                className={`productDetail_timingsCard_wrapper`}
                error={true}
              />
            </div>

            {/*  Un availibility Calendar */}
            <div
              className="dailyOverviewCalenderWrapper selectPricePerDayCalenderWrapper productDetailCalenderWrapper"
              style={{ flex: "6 0" }}
            >
              <CalenderHeader
                calenderType={calenderType}
                setCalenderType={setCalenderType}
                calendarRef={calendarRef}
                noFilters={true}
              />
              <div className="selectPricePerDayContainer">
                <div className="selectPricePerDayCalenderWrapper">
                  <FullCalendar
                    ref={calendarRef}
                    height={350}
                    headerToolbar={false}
                    viewClassNames={"dailyOverViewCalender"}
                    dayHeaderClassNames="dayHeaderClassNames"
                    dayCellClassNames={"calender_blocks"}
                    eventClassNames="price_per_day_calender_event"
                    plugins={[dayGridPlugin, timeGridPlugin]}
                    initialView="dayGridMonth"
                    events={selectedCadData}
                    eventContent={renderEventContent}
                    selectMirror={true}
                    eventClick={() => {}}
                    dayMaxEvents={true}
                    slotEventOverlap={false}
                    slotLabelInterval={{ hour: 1 }}
                    allDaySlot={false}
                    dayMaxEventRows={true}
                    dayCellContent={renderDay}
                    eventMaxStack={calenderType === "timeGridWeek" ? 1 : 8}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="product_description">
        <div className="product_description_navbar">
          <span
            onClick={() => setDescription_nav("description")}
            style={{
              color: description_nav === "description" ? "white" : null,
              borderBottom:
                description_nav === "description" ? "1px solid white" : null,
            }}
          >
            Description
          </span>
          <span
            onClick={() => setDescription_nav("features")}
            style={{
              color: description_nav === "features" ? "white" : null,
              borderBottom:
                description_nav === "features" ? "1px solid white" : null,
            }}
          >
            Features
          </span>
          {/* <span
            onClick={() => setDescription_nav('reviews')}
            style={{
              display: 'none',
              color: description_nav === 'reviews' ? 'white' : null,
              borderBottom:
                description_nav === 'reviews' ? '1px solid white' : null,
            }}
          >
            Reviews
          </span> */}
          <span
            onClick={() => setDescription_nav("locations")}
            style={{
              display: "none",
              color: description_nav === "locations" ? "white" : null,
              borderBottom:
                description_nav === "locations" ? "1px solid white" : null,
            }}
          >
            Locations
          </span>
        </div>
        <div className="product_description_main">
          <div className="description_detail">
            {description_nav === "description" ? (
              <div className="description">
                {/* {data?.description} */}
                {isReadMore
                  ? data?.description?.slice(0, 500)
                  : data?.description}
                <span
                  onClick={() => setIsReadMore(!isReadMore)}
                  className={`read_more ${isReadMore ? null : 'read_less'}`}
                >
                  {isReadMore ? '...READ MORE' : 'SHOW LESS'}
                  <svg width='8' viewBox='0 0 8 14' fill='none'>
                    <path
                      d='M-8.74228e-08 12L5 7L-5.24537e-07 2L0.999999 -4.37114e-08L8 7L1 14L-8.74228e-08 12Z'
                      fill='#4FC8E9'
                    />
                  </svg>
                </span>
              </div>
            ) : description_nav === "features" ? (
              data?.features?.length !== 0 && (
                <FeaturesCard features={data?.features} />
              )
            ) : null}
          </div>
          <div className="description_video">
            <div className="description_video_heading">Brand Video</div>
            <div className="description_video_main">
              <video controls width="100%">
                <source
                  src="https://aviosoftimages-new.s3.amazonaws.com/RTRC_Aces_30secspot_Epic_h265.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
